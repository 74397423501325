import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
//import HomeView from "../views/HomeView.vue";
import liff from "@line/liff/dist/lib";
import { Auth } from "aws-amplify";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/CommonFrameView.vue"),
    children: [
      // {
      //   path: "/",
      //   name: "home",
      //   component: () => import("@/views/HomeView.vue"),
      // },
      // {
      //   path: "/",
      //   component: () => import("@/views/DashboardView.vue"),
      // },
      {
        path: "/",
        component: () => import("@/views/InformationView.vue"),
      },
      {
        path: "/informationcategory",
        component: () => import("@/views/InformationCategoryView.vue"),
      },
      {
        path: "/garbage",
        component: () => import("@/views/GarbageView.vue"),
      },
      {
        path: "/garbagecalendar",
        component: () => import("@/views/GarbageCalendar.vue"),
      },
      {
        path: "/reservationcalendar",
        component: () => import("@/views/ReservationCalendarView.vue"),
      },
      {
        path: "/reservation/:targetid/:categoryid/:contentid/:scheduleddate/:starttime/:endtime/:plus_disabled",
        component: () => import("@/views/ReservationView.vue"),
      },
      {
        path: "/reservationonline",
        component: () => import("@/views/ReservationOnlineView.vue"),
      },
      {
        path: "/reservationcategory",
        component: () => import("@/views/ReservationCategoryView.vue"),
      },
      {
        path: "/reservationcontent",
        component: () => import("@/views/ReservationContentView.vue"),
      },
      {
        path: "/reservationtarget",
        component: () => import("@/views/ReservationTargetView.vue"),
      },
      {
        path: "/inquiry",
        component: () => import("@/views/InquiryView.vue"),
      },
      {
        path: "/colorset",
        component: () => import("@/views/ColorSetView.vue"),
      },
      {
        path: "/userListView",
        component: () => import("@/views/Admin/UserListView.vue"),
      },
      {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/AboutView.vue"),
      },
    ],
    meta: { requireAuth: true },
  },
  {
    path: "/auth",
    name: "authview",
    component: () => import("@/views/AuthView.vue"),
  },
  {
    path: "/401",
    name: "unauthorized",
    component: () => import("@/views/Error/Unauthorized.vue"),
  },
  {
    path: "/403",
    name: "forbidden",
    component: () => import("@/views/Error/Forbidden.vue"),
  },
  {
    path: "/500",
    name: "internal-server-error",
    component: () => import("@/views/Error/InternalServerError.vue"),
  },
  {
    path: "*",
    name: "*",
    component: () => import("@/views/Error/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// ログインと指定userIDによるナビゲーションガード
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    Auth.currentAuthenticatedUser()
      .then(async () => {
        console.log((await Auth.currentAuthenticatedUser()).username);
        console.log(await Auth.currentAuthenticatedUser());
        next();
      })
      .catch(() => {
        next({
          path: "/auth",
        });
      });
  }
  next();
});

export default router;
