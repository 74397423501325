












import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Auth, Hub, Logger } from "aws-amplify";

@Component
export default class App extends Vue {
  signedIn = false;
  logger = new Logger("Logger");

  async beforeCreate() {
    // Amplify Hubのイベント検知
    // https://docs.amplify.aws/lib/utilities/hub/q/platform/js/

    // Amplify Logger
    // https://docs.amplify.aws/lib/utilities/logger/q/platform/js/
    Hub.listen("auth", (data) => {
      const { payload } = data;
      // サインイン
      if (payload.event === "signIn") {
        this.signedIn = true;
        this.$router.push("/");
        this.logger.info("user signed in");
      }
      // サインアウト
      if (payload.event === "signOut") {
        this.$router.push("/auth");
        this.signedIn = false;
        this.logger.info("user signed out");
      }
    });

    Auth.currentAuthenticatedUser()
      .then(() => {
        this.signedIn = true;
      })
      .catch(() => {
        this.signedIn = false;
      });
  }
}
