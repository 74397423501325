import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseColor: "teal",
    darken1Color: "teal darken-1",
    darken2Color: "teal darken-2",
    ligthten1Color: "teal ligthten-1",
    ligthten2Color: "teal ligthten-2",
  },
  getters: {
    baseColor(state) {
      return state.baseColor;
    },
    darken1Color(state) {
      return state.darken1Color;
    },
    darken2Color(state) {
      return state.darken2Color;
    },
    ligthten1Color(state) {
      return state.ligthten2Color;
    },
    ligthten2Color(state) {
      return state.ligthten2Color;
    },
  },
  mutations: {
    colorset(state, color) {
      // Json文字列に変換しlocalStorageへ保存
      localStorage.setItem("store", JSON.stringify(color));
    },
    colorget(state) {
      if (localStorage.getItem("store")) {
        // localStorageから取得したJson文字列をパース
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const store = JSON.parse(localStorage.getItem("store")!);
        // stateを置き換えます。
        this.replaceState(Object.assign(state, store));

        // ※ ちなみに以下はNGです。stateプロパティは読み取り専用なので。
        // this.state = store;
      }
    },
  },
  actions: {
    setColor({ commit }, color) {
      commit("colorset", color);
    },
    getColor({ commit }) {
      commit("colorget");
    },
  },
  modules: {},
});
