import Vue from "vue";
import App from "./App.vue";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";
import { Translations } from "@aws-amplify/ui-components";
import { Amplify, I18n } from "aws-amplify";
import { ja } from "./locale/ja";
import { cognitoConstants } from "@/cognito/auth";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import liff from "@line/liff/dist/lib";

Amplify.configure(cognitoConstants);
I18n.putVocabulariesForLanguage("ja-JP", ja(Translations));
I18n.setLanguage("ja-JP");

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];
Vue.config.productionTip = false;

// liff init
(async () => {
  console.log(`environment is :${process.env.NODE_ENV}`);
  if (process.env.NODE_ENV === "development") {
    // 開発環境であればLIFFログインを有効にしない
    // Cognitoログインが整ったのでLIFFログインをコメントアウト
  } else {
    // await liff.init({ liffId: process.env.VUE_APP_LIFFID ?? "" });
    // if (liff.isLoggedIn()) {
    //   const d = await liff.getProfile();
    //   console.log(d);
    // } else {
    //   await liff.login();
    // }
  }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
})();
